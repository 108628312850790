import React from "react"
import { graphql } from "gatsby"
import classnames from "classnames"

import { Layout, SEO, Container } from "../components"
import styles from "./index.module.scss"
import { pageTypes } from "../types"
import { getContentFromAllFiles } from "../utils/content"

const PressKitPage = ({ data, pageContext: { locale } }) => {
  const { 
    html,
    title,
    description,
    subTitle,
    download
  } = getContentFromAllFiles(data, 'pressKit');
  return (
    <Layout locale={locale} content={getContentFromAllFiles(data, 'site')}>
      <SEO title={`${title} | Receiver 2`} description={description} path="/press-kit/" lang={locale} />
      <Container className={styles.container}>
        <div className={styles.intro}>
          <h1>{title}</h1>
          <h4>{subTitle}</h4>
        </div>
        <hr className={styles.divider} />
        <div className={classnames(styles.content, "html")} dangerouslySetInnerHTML={{ __html: html }} />
        <div>
          <br />
          <a className="button" href={download.link} title={download.label}>{download.label}</a>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query pressKitPagePageQuery($locale: String) {
    allFile(filter: {relativeDirectory: {eq: $locale}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            html
            frontmatter {
              title
              description
              subTitle
              download {
                link
                label
              }
            }
            ...Site
          }
        }
      }
    }
  }
`

PressKitPage.propTypes = pageTypes

export default PressKitPage
